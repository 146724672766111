import "./src/styles/main.css";
import React from "react";

export const onRouteUpdate = () => {
  const lineElement = document.querySelector(".wpr-timeline-fill");
  const targetElement = document.querySelector(".wpr-timeline-centered");
  const iconElements = document.querySelectorAll(".wpr-main-line-icon.wpr-icon");

  if (lineElement && targetElement && iconElements.length > 0) {
    let isCounting = false;
    const threshold = 170; 
    const viewportHeight = window.innerHeight;

    const calculateLineHeight = () => {
      const scrollPosition = window.scrollY;
      const targetPosition = targetElement.getBoundingClientRect().top + scrollPosition;

      if (scrollPosition >= targetPosition - viewportHeight + threshold) {
        isCounting = true;
        const lineHeight =
          ((scrollPosition - (targetPosition - viewportHeight + threshold)) / viewportHeight) * 100;
        lineElement.style.height = `${Math.max(lineHeight, 0)}%`;

        iconElements.forEach((iconElement) => {
          const iconPosition = iconElement.getBoundingClientRect().top + scrollPosition;
          const lineBottom =
            lineElement.getBoundingClientRect().top +
            lineElement.getBoundingClientRect().height +
            scrollPosition;

          if (lineBottom >= iconPosition) {
            iconElement.classList.add("wpr-change-border-color");
          } else {
            iconElement.classList.remove("wpr-change-border-color");
          }
        });
      } else {
        isCounting = false;
        lineElement.style.height = "0";
        iconElements.forEach((iconElement) =>
          iconElement.classList.remove("wpr-change-border-color")
        );
      }
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          calculateLineHeight(); 
        }
      });
    });

    observer.observe(targetElement);

    window.addEventListener("scroll", calculateLineHeight);

    calculateLineHeight();
  }
};

